import { createAlchemyWeb3 } from '@alch/alchemy-web3';
import contractABI from './contract-abi.json';

require('dotenv').config();

const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY;
const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
const metaMaskUrl = process.env.REACT_APP_METAMASK_URL;
const mintPrice = parseFloat(process.env.REACT_APP_MINT_PRICE);
const txBaseURL = process.env.REACT_APP_TX_BASE_URL;

const web3 = createAlchemyWeb3(alchemyKey);
window.contract = new web3.eth.Contract(contractABI, contractAddress);

export const connectWallet = async () => {
  if (!window.ethereum) return {
    address: null,
    notice: (
      <p>
        No wallet found. <a
          target="_blank" 
          href={metaMaskUrl}
        >
          Download MetaMask here
        </a>, or, if you've already installed a wallet, ensure it's enabled and 
        then refresh the page (you might need to refresh a couple of times).
      </p>
    ),
    isError: false
  };
  try {
    const customerAddresses = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    return {
      address: customerAddresses[0],
      notice: `Wallet connected: ${prettifyAddress(customerAddresses[0])}`,
      isError: false
    };
  } catch (error) {
    return {
      address: null,
      notice: null,
      isError: true
    };
  }
};

export const getCurrentlyConnectedWallet = async () => {
  if (!window.ethereum) return {
    address: null,
    notice: null,
    isError: false
  };
  try {
    const customerAddresses = await window.ethereum.request({
      method: "eth_accounts",
    });
    return {
      address: customerAddresses.length > 0 ? customerAddresses[0] : null,
      notice: customerAddresses.length > 0 
        ? `Wallet connected: ${prettifyAddress(customerAddresses[0])}`
        : null,
      isError: false
    }
  } catch (error) {
    return {
      address: null,
      notice: null,
      isError: true
    };
  }
};

export const fetchIsSaleActive = async () => {
  return new Promise((resolve, reject) => {
    contract.methods.getIsSaleActive().call().then(result => {
      resolve(result);
    }).catch(error => {
      resolve(true);
    });
  });
}

export const fetchNumTokensAvailableToMint = async () => {
  return new Promise((resolve, reject) => {
    contract.methods.getNumTokensAvailableToMint().call().then(result => {
      resolve(parseInt(result));
    }).catch(error => {
      resolve(null);
    });
  });
}

export const mintTokens = async (quantity, customerAddress) => {
  if (!window.ethereum || !customerAddress) return {
    notice: (
      <p>
        No wallet found. <a
          target="_blank" 
          href={metaMaskUrl}
        >
          Download MetaMask here
        </a>, or, if you've already installed a wallet, ensure it's enabled
        and connected to this site, and then refresh the page.
      </p>
    ),
    wasSuccessful: false
  }

  // Validate the given quantity of tokens to mint
  if (
    quantity > (
      await fetchNumTokensAvailableToMint()
    ).numTokensAvailableToMint
  ) return {
    notice: 'Exceeds the number of tokens available for minting',
    wasSuccessful: false
  }

  // Define the transaction parameters
  const txParameters = {
    to: contractAddress,
    from: customerAddress,
    data: window.contract.methods.mintTokens(quantity).encodeABI(),
    value: '0x' + (
      quantity * web3.utils.toWei(String(mintPrice), 'ether')
    ).toString(16)
  };

  // Sign the transaction via the wallet
  try {
    const txHash = await window.ethereum.request({
      method: 'eth_sendTransaction',
      params: [txParameters]
    });
    return {
      notice: (
        <p>
          Your transaction has been submitted! <a 
            target="_blank" 
            href={`${txBaseURL}${txHash}`}
          >
            View it on Etherscan.
          </a>
        </p>
      ),
      wasSuccessful: true
    }
  } catch (error) {
    return {
      notice: error.message,
      wasSuccessful: false
    }
  }
};

export const prettifyAddress = (address) => {
  return (
    String(address).substring(0, 5) +
    "..." +
    String(address).substring(38)
  );   
};
