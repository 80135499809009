import { useEffect, useState } from "react";
import {
  connectWallet,
  getCurrentlyConnectedWallet,
  fetchIsSaleActive,
  fetchNumTokensAvailableToMint,
  mintTokens,
  prettifyAddress
} from "./utils/ethereum.js";

require('dotenv').config();

const etherscanUrl = process.env.REACT_APP_ETHERSCAN_URL;
const givingBlockUrl = process.env.REACT_APP_GIVING_BLOCK_URL;
const gorillaFundUrl = process.env.REACT_APP_GORILLA_FUND_URL;
const launchDate = process.env.REACT_APP_LAUNCH_DATE;
const maxNumTokens = parseInt(process.env.REACT_APP_MAX_NUM_TOKENS);
const mintPrice = process.env.REACT_APP_MINT_PRICE;
const raribleUrl = process.env.REACT_APP_RARIBLE_URL;
const sampleGravitationNumbers = JSON.parse(
  process.env.REACT_APP_SAMPLE_GRAVITATION_NUMBERS
);
const twitterUrl = process.env.REACT_APP_TWITTER_URL;

const populateGallery = () => {
  const gravitationNumber = parseInt(sampleGravitationNumbers[
    Math.round(Math.random() * (sampleGravitationNumbers.length - 1))
  ]);
  const img = document.getElementsByClassName('gallery__image')[0];
  img.src = `./gravitation_images/${gravitationNumber}.png`;
  img.onload = () => {
    const imgLabel = document.getElementsByClassName('gallery__label')[0];
    imgLabel.innerHTML = 'Gravitation #' + prettifyBigNumber(
      gravitationNumber
    );
  };
};

const prettifyBigNumber = (number) => {
  return number.toLocaleString("en-US");
};

const useScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const updatePosition = () => {
      setScrollPosition(window.pageYOffset);
    }
    window.addEventListener('scroll', updatePosition);
    updatePosition();
    return () => window.removeEventListener('scroll', updatePosition);
  }, []);

  return scrollPosition;
};

const GravitationsShop = (props) => {
  const [fadeinIntervalId, setFadeinIntervalId] = useState(null);
  const [fadeoutIntervalId, setFadeoutIntervalId] = useState(null);
  const [isSaleActive, setIsSaleActive] = useState(true);
  const [
    isWalletConnectionRequestPending, 
    setIsWalletConnectionRequestPending
  ] = useState(false);
  const [notice, setNotice] = useState(null);
  const [noticeTimeoutId, setNoticeTimeoutId] = useState(null);
  const [numTokensMinted, setNumTokensMinted] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const scrollPosition = useScrollPosition();
  const [walletAddress, setWalletAddress] = useState(null);

  useEffect(async () => {
    setIsSaleActive(await fetchIsSaleActive());
    const numTokensAvailableToMint = await fetchNumTokensAvailableToMint();
    if (numTokensAvailableToMint !== null) setNumTokensMinted(
      maxNumTokens - numTokensAvailableToMint
    );
    // numTokensAvailableToMint should never be null, but if it is, just set 
    // it to 0
    if (numTokensAvailableToMint === null) setNumTokensMinted(0);

    await populateGallery();
    setInterval(populateGallery, 4000);

    const { address, notice, isError } = await getCurrentlyConnectedWallet();
    if (address) {
      setWalletAddress(address);
      setNoticeWithVisualFeedback(notice, 4000);
    } else if (!isError) {
      tryConnectingToWallet();
    }
    // Swallow any error; there's nothing we can do about it

    addWalletListener();
  }, []);

  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        setWalletAddress(accounts.length > 0 ? accounts[0] : null);
      });
    }
  };

  const fadeElementIn = (element) => {
    let op = 0.1;
    element.style.display = 'block';
    const intervalId = setInterval(function () {
      if (op >= 1) clearInterval(intervalId);
      element.style.opacity = op;
      element.style.filter = `alpha(opacity=${op * 100})`;
      op += op * 0.1;
    }, 30);
    setFadeinIntervalId(intervalId);
  };

  const fadeElementOut = (element) => {
    let op = 1;
    const intervalId = setInterval(function () {
      if (op <= 0.1) {
        clearInterval(intervalId);
        element.style.display = 'none';
      }
      element.style.opacity = op;
      element.style.filter = `alpha(opacity=${op * 100})`;
      op -= op * 0.1;
    }, 30);
    setFadeoutIntervalId(intervalId);
  };

  const getScrollHeight = () => {
    if (typeof window === `undefined`) return 0;
    return window.scrollY
  };

  const handleConnectWalletPress = async () => {
    if (walletAddress || isWalletConnectionRequestPending) return;
    tryConnectingToWallet();
  };

  const handleMintPress = async () => {
    if (quantity === '' || parseInt(quantity) === 0) {
      setNoticeWithVisualFeedback('Quantity must be at least 1', 4000);
    } else {
      const { notice, wasSuccessful } = await mintTokens(
        parseInt(quantity), 
        walletAddress
      );
      setNoticeWithVisualFeedback(notice, wasSuccessful ? 12000 : 8000);
    }
  };

  const handleQuantityChange = (event) => {
    const value = event.target.value;
    setQuantity(prevQuantity => {
      if (value === '') return value;
      if (
        !(new RegExp(/^\d+$/)).test(value)
        || parseInt(value) > maxNumTokens - numTokensMinted
      ) return prevQuantity;
      return value;
    })
  };

  const setNoticeWithVisualFeedback = (notice, duration) => {
    setNotice(notice);
    if (notice) {
      // Get the notice element
      const noticeElement = document.getElementsByClassName('notice')[0];

      // Hide the notice element if it was already visible and ensure any  
      // active timers and intervals are clear
      if (fadeinIntervalId) clearInterval(fadeinIntervalId);
      if (fadeoutIntervalId) clearInterval(fadeoutIntervalId);
      if (noticeTimeoutId) clearTimeout(noticeTimeoutId);
      noticeElement.style.display = 'none';

      // Display the notice element
      fadeElementIn(noticeElement);
      setNoticeTimeoutId(setTimeout(fadeElementOut, duration, noticeElement));
    }
  };

  const tryConnectingToWallet = async () => {
    if (!isWalletConnectionRequestPending) {
      setIsWalletConnectionRequestPending(true);
      const { address, notice, isError } = await connectWallet();
      if (!isError) {
        setWalletAddress(address);
        setNoticeWithVisualFeedback(notice, address ? 4000 : 8000);
        setIsWalletConnectionRequestPending(false);
      } else {
        // We assume that an error means a request is pending
        setNoticeWithVisualFeedback(
          'A wallet connection request is pending. Either 1) refresh the ' +
          'page to reinitiate the connection, or 2) open your wallet, ' +
          'connect manually, and refresh.',
          8000
        );
      }
    } else {
      setNoticeWithVisualFeedback(
        'A wallet connection request is pending. Either 1) refresh the ' +
        'page to reinitiate the connection, or 2) open your wallet, ' +
        'connect manually, and refresh.',
        8000
      );
    }
  }

  return (
    <div className="gravitations-shop">
      <button
        className={`button ${
          isWalletConnectionRequestPending || walletAddress 
            ? 'button--disabled' 
            : ''
        } connect-wallet-button`}
        onClick={handleConnectWalletPress}
      >
        {
          isWalletConnectionRequestPending
            ? 'Connection pending...'
            : walletAddress
              ? `Connected: ${prettifyAddress(walletAddress)}`
              : 'Connect wallet'
        }
      </button>
      {scrollPosition > 100 &&
        <div className="sticky-bar">
          <div className="sticky-bar__title">
            Gravitations
          </div>
          <button
            className={`button ${
              isWalletConnectionRequestPending || walletAddress 
                ? 'button--disabled' 
                : ''
            } sticky-bar__connect-wallet-button`}
            onClick={handleConnectWalletPress}
          >
            {
              isWalletConnectionRequestPending
                ? 'Connection pending...'
                : walletAddress
                  ? `Connected: ${prettifyAddress(walletAddress)}`
                  : 'Connect wallet'
            }
          </button>
        </div>
      }
      <div className="floor">
        <div className="sign">
          Gravitations
        </div>
        <div className="poster">
          <i>Gravitations</i> is a collection of {
            prettifyBigNumber(maxNumTokens)
          } unique generative digital pieces, 
          made available as non-fungible tokens (NFTs). When you mint a 
          Gravitation, your purchase is added to the Ethereum blockchain, 
          creating an immutable record of provenance.
        </div>
        <div className="gallery">
          <img
            alt="Gravitation"
            className="gallery__image"
            src="./gravitation_placeholder.png"
            width="500"
            height="500"
          />
          <p className="gallery__label">Gravitation #-</p>
          <p className="gallery__note">
            Gravitations are a celebration of how even the barest forms come 
            to life when imbued with one of nature's preeminent mathematical 
            motifs: the Gaussian. Each Gravitation was generated from the 
            same program but with a different seed; consequently, though 
            the {prettifyBigNumber(maxNumTokens)} pieces are united by a 
            common theme, no two are exactly alike.
          </p>
        </div>
        <div className="checkout-container">
          <div className="checkout">
            {isSaleActive && numTokensMinted < maxNumTokens &&
              <div className="checkout__quantity-and-mint-button">
                <form>
                  <label
                    className="checkout__quantity-label"
                    htmlFor="checkout__quantity-input"
                  >
                    Qty:
                  </label>
                  <input
                    size={3}
                    type="tel"
                    onChange={handleQuantityChange}
                    onFocus={(event) => event.target.select()}
                    className="checkout__quantity-input"
                    value={quantity}
                  />
                </form>
                <button 
                  className="button checkout__mint-button" 
                  onClick={handleMintPress}
                >
                  Mint
                </button>
              </div>
            }
            {(!isSaleActive || numTokensMinted >= maxNumTokens) &&
              <div className="checkout__inactive-sale">
                { !isSaleActive 
                    ? `Minting opens ${launchDate}`
                    : 'SOLD OUT' }
              </div>
            }
            {isSaleActive &&
              <>
                <div className="checkout__tally">
                  { 
                    prettifyBigNumber(
                      typeof(numTokensMinted) === 'number' 
                        ? numTokensMinted 
                        : '-'
                    ) 
                  } / { 
                    prettifyBigNumber(maxNumTokens) 
                  } minted
                </div>
                <div className="checkout__price">
                  {`Price per Gravitation: Ξ${mintPrice} + gas`} 
                </div>
                {/*<div className="checkout__rarible">
                  Shop for Gravitations on <a 
                    href={raribleUrl} 
                    target="_blank">
                    Rarible
                  </a>
                </div>*/}
                <div className="checkout__donation">
                  Once all tokens have been minted, 20% of proceeds will be 
                  donated to the <a
                    href={gorillaFundUrl}
                    target="_blank">
                    Dian Fossey Gorilla Fund
                  </a> through the <a
                    href={givingBlockUrl}
                    target="_blank">
                    Giving Block
                  </a>.
                </div>
              </>
            }
          </div>
        </div>
        <div className="footer">
          <div className="footer__external-links">
            <a
              href={etherscanUrl}
              target="_blank"
              title="Etherscan"
            >
              <img 
                className="footer__external-link footer__external-link-1" 
                alt="Etherscan" 
                src="etherscan_logo--light.png" 
                width="48" 
                height="48" 
              />
            </a>
            <a
              href={raribleUrl}
              target="_blank"
              title="Rarible"
            >
              <img 
                className="footer__external-link footer__external-link-2" 
                alt="Rarible" 
                src="rarible_logo--light.png" 
                width="48" 
                height="48" 
              />
            </a>
            <a
              href={twitterUrl}
              target="_blank"
              title="Twitter"
            >
              <img 
                className="footer__external-link footer__external-link-3" 
                alt="Twitter" 
                src="twitter_logo--light.png" 
                width="48" 
                height="48" 
              />
            </a>
          </div>
          <div className="footer__copyright">
            Each Gravitation is licensed by Ty Lazar under the CC&#8239;BY&#8239;4.0 
            license. Website copyright ©&#8239;{new Date().getFullYear()} by 
            Ty Lazar.
          </div>
        </div>
      </div>
      <div className="notice">
        {notice}
      </div>
    </div>
  );
};

export default GravitationsShop;
