import GravitationsShop from './GravitationsShop'

function App() {
  return (
    <div className="App">
      <GravitationsShop></GravitationsShop>
    </div>
  );
}

export default App;
